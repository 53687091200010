import { Component, ViewChild } from '@angular/core';
import { DiService, SessionService } from '@seech-sub/core-ng';
import { NavItem, MenuItem, MobileNavComponent } from '@seech-sub/layout-ng';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  @ViewChild('mobileNav') mobileNav!: MobileNavComponent;

  showFooter = true;
  sidebarCollapsed = false;
  sidebarWidth = '20%';

  constructor(
    private coreDi: DiService,
    private sessionService: SessionService
  ) {}

  showMenu(): boolean {
    return !this.coreDi.environment?.production;
  }

  navItems: NavItem[][] = [
    [
      { label: 'Dashboard', link: 'dashboard', icon: 'dashboard-rounded' },
      { label: 'My Wallet', link: 'wallet', icon: 'wallet' },
      { label: 'Transactions', link: 'transactions', icon: 'receipt' },
      {
        label: 'Subscription',
        link: 'subscriptions',
        // isHidden: this.showMenu(),
        icon: 'paid',
      },
    ],
    [
      {
        label: 'Settings',
        link: 'settings',
        // isHidden: this.showMenu(),
        icon: 'settings',
      },
      {
        label: 'Help and Support',
        link: 'support',
        // isHidden: this.showMenu(),
        icon: 'support',
      },
    ],
  ];

  headerMenuItems: MenuItem[] = [
    {
      label: 'Accounts',
      routerLink: environment.ACCOUNT_WEB_URL,
      external: true,
    },
    { label: 'Settings', routerLink: '/settings' },
    { label: 'Log out', action: () => this.onLogout() },
  ];

  mobileNavItems: NavItem[] = [
    { label: 'Dashboard', icon: 'dashboard-rounded', link: 'home' },
    { label: 'My Wallet', icon: 'wallet', link: 'wallet' },
    { label: 'Transactions', icon: 'receipt', link: 'transactions' },
    { label: 'Subscription', icon: 'paid', link: 'subscriptions' },
    { label: 'Menu', icon: 'menu', link: '', action: () => this.mobileNav.toggleSideNav() }
  ]

  onSidebarToggle(isCollapsed: boolean) {
    this.sidebarCollapsed = isCollapsed;
  }

  // Method to toggle the sidebar collapse state
  toggleSidebar(): void {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }

  onLogout() {
    this.sessionService.logout();
  }
}
