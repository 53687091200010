import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { VALIDATION } from '../../../../constants';

@Component({
  selector: 'seech-international-account',
  templateUrl: './international-account.component.html',
  styleUrl: './international-account.component.scss',
})
export class InternationalAccountComponent {
  @Input() bankNames: any[] = [];
  internatiolAccountForm: FormGroup;

  @Output() formValuesChanged = new EventEmitter<any>(); // Output for two-way binding

  constructor(private fb: FormBuilder) {
    this.internatiolAccountForm = this.fb.group({
      fullName: ['', VALIDATION.accountNameValidator],
      accountNumber: ['', VALIDATION.accountNumberValidator],
      bankName: ['', VALIDATION.bankNameValidator],
      swiftSortNumber: ['', VALIDATION.swiftSortNumberValidator],
      routingNumber: ['', VALIDATION.routingNumberValidator],
    });

    // Emit form values on changes
    this.internatiolAccountForm.valueChanges.subscribe((values: any) => {
      this.formValuesChanged.emit(values);
    });
  }

  handleBankNamePagination(): void {
    // Increase page size or page number and fetch more data
  }

  handleBankNameValueChanged(event: any) {
    this.internatiolAccountForm.patchValue({ bankName: event.value });
  }
}
