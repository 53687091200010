import { NgModule } from '@angular/core';
import { ApiModule, Configuration } from './generated';
import { PaymentAuthorizationComponent } from './components/payment-authorization/payment-authorization.component';
import { UxNgModule } from '@seech/ux-ng';

@NgModule({
  imports: [
    ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: '',
        })
    ),
    PaymentAuthorizationComponent,
    UxNgModule
  ],
  exports: [ApiModule, PaymentAuthorizationComponent],
  declarations: [],
})
export class PaymentNgModule {}
