<section id="new-bank-payment" [formGroup]="newBankPaymentForm">
    <div class="col-12">
        <seech-select label="Country" formControlName="country" id="bank-country" [options]="countries"
            placeholder="Select your country" (reachedEndOfList)="handleCountryPagination()"
            (selectedValueChange)="handleCountryValueChanged($event)" sch-validations />
    </div>

    <!-- Show the appropriate component based on the selected country -->
    <ng-container [ngSwitch]="newBankPaymentForm.get('country')?.value">
        <seech-usa-account *ngSwitchCase="'USA'" [bankNames]="bankAccounts"
            (formValuesChanged)="onChildFormValuesChanged($event )" />

        <seech-international-account *ngSwitchDefault [bankNames]="bankAccounts"
            (formValuesChanged)="onChildFormValuesChanged($event)" />
    </ng-container>
</section>