<form [formGroup]="newBankPaymentForm">
    <section id="new-bank-payment">
        <div class="col-12">
            <seech-select label="Country" [isContentLoading]="isLoading" formControlName="country" id="bank-country"
                [options]="countries" placeholder="Select your country" (reachedEndOfList)="handleCountryPagination()"
                [readonly]="isEditing" (selectedValueChange)="handleCountryValueChanged($event)"
                (searchTermChange)="onSearchValueChange($event)" sch-validations />
        </div>

        <div class="col-12">
            <seech-input formControlName="fullName" label="Account Name" id="account-name" placeholder="Enter full name"
                sch-validations />
        </div>

        <div class="col-12" *ngIf="newBankPaymentForm.get('accountNumber')">
            <seech-input formControlName="accountNumber" [label]="bankAccountConfig.accountNumber.title"
                id="account-number" placeholder="Enter {{ bankAccountConfig.accountNumber.title }}" sch-validations />
        </div>

        <div class="col-12">
            <seech-select label="Bank Name" [isContentLoading]="isBankContentLoading" formControlName="bankName"
                id="bank-name" [options]="selectedBanks" [selectedValue]="bankSelectedValue" placeholder="Select your bank"
                (reachedEndOfList)="handleBankPagination()" (selectedValueChange)="handleBankValueChanged($event)"
                sch-validations />
        </div>

        <div class="col-12" *ngIf="newBankPaymentForm.get('localBankId')">
            <seech-input formControlName="localBankId" [label]="bankAccountConfig.localBankId.title" id="local-bank-id"
                placeholder="Enter {{ bankAccountConfig.localBankId.title }}" sch-validations />
        </div>

        <div class="col-12" *ngIf="newBankPaymentForm.get('internationalBankId')">
            <seech-input formControlName="internationalBankId" [label]="bankAccountConfig.internationalBankId.title"
                id="intl-bank-id" placeholder="Enter {{ bankAccountConfig.internationalBankId.title }}"
                sch-validations />
        </div>
    </section>
</form>