/* eslint-disable @nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutNgModule } from '@seech-sub/layout-ng';
import { environment } from '../../environments/environment';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutNgModule.forRoot({
      hasSearch: false,
      sharedCdnUrl: environment.SHARED_CDN_URL,
      globalCdnUrl: environment.GLOBAL_CDN_URL,
    }),
  ],
  exports: [],
})
export class LayoutModule {}

