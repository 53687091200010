import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlsNgModule,
  RadioDirective,
  SeechValidationsDirective,
} from '@seech/controls-ng';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CardValidationService } from '../../../services/card-validation.service';
import { BankCardTypeLabels, VALIDATION } from '../../../constants';
import {  BankCardResponse } from '../../../models';
@Component({
  selector: 'seech-new-card-payment',
  standalone: true,
  imports: [
    CommonModule,
    ControlsNgModule,
    FormsModule,
    ReactiveFormsModule,
    SeechValidationsDirective,
    RadioDirective,
  ],
  templateUrl: './new-card-payment.component.html',
  styleUrl: './new-card-payment.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NewCardPaymentComponent implements OnChanges, OnInit {
  cardPaymentForm!: FormGroup;
  selectedDate: any = '';
  cardNumberValid = true;
  cvvValid = true;
  cardBrandValid = true;
  cardBrandErrorMessage = '';
  chosenCardCvvLength!: number;
  cardBrand: any[] = [];

  cardTypes: any[] = BankCardTypeLabels;

  @Input() editableCardItem!: BankCardResponse;
  @Input() showRequiredFieldErrors = false;
  @Output() formValuesChanged = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private cardValidationService: CardValidationService
  ) {}

  ngOnInit() {
    this.buildCardPaymentForm();
    this.getCardBrands();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editableCardItem']) {
      this.buildCardPaymentForm();
    }
    if (changes['showRequiredFieldErrors']) {
      this.revealValidationErrors();
    }
  }

  buildCardPaymentForm() {
    this.cardPaymentForm = this.fb.group({
      fullName: [
        this.editableCardItem?.cardHolderName || '',
        VALIDATION.nameValidator,
      ],
      cardNumber: [
        this.editableCardItem?.cardNumber || '',
        VALIDATION.cardNumberValidator,
      ],
      cardBrand: [
        this.editableCardItem?.cardBrand || '',
        VALIDATION.cardBrandValidator,
      ],
      expirationDate: [
        this.editableCardItem
          ? `${this.editableCardItem.expirationMonth.trim()}/${this.editableCardItem.expirationYear.trim()}`
          : '',
        VALIDATION.dateValidator,
      ],
      cvv: [this.editableCardItem?.cvv || '', [VALIDATION.cvvValidator]],
      cardType: [this.editableCardItem?.cardType || ''],
    });

    if (this.editableCardItem) {
      this.onCardNumberChange();

      this.formValuesChanged.emit(this.cardPaymentForm.value);
    }

    // Emit form values on changes
    this.cardPaymentForm.valueChanges.subscribe((values) => {
      if (this.cardPaymentForm.valid) {
        this.formValuesChanged.emit(values);
      }
    });
  }

  get cardPaymentFormControls() {
    return this.cardPaymentForm.controls;
  }

  get formCardNumber(): string {
    return this.cardPaymentFormControls['cardNumber'].value || '';
  }

  getErrorMessage(instance: string) {
    if (instance === 'cardNumber' && !this.cardNumberValid) {
      return 'Card number is invalid';
    } else if (instance === 'cvv' && !this.formCardNumber) {
      return 'Please enter card number';
    } else if (instance === 'cvv' && !this.cvvValid) {
      return 'CVV is invalid';
    } else {
      return '';
    }
  }

  onCardNumberChange() {
    this.cardNumberValid = true;

    const brand = this.cardValidationService.identifyCardBrand(
      this.formCardNumber
    );

    if (brand) {
      this.cardPaymentForm.patchValue({
        cardBrand: brand.name,
      });

      this.chosenCardCvvLength = brand.cvvLength;

      if (
        this.cardPaymentFormControls['cvv'].value.length ===
        this.chosenCardCvvLength
      ) {
        this.cvvValid = true;
      }
    }
  }

  validateCardNumber() {
    this.cardNumberValid = this.cardValidationService.validateCardNumber(
      this.formCardNumber
    );

    const detectedCardBrand = this.cardValidationService.identifyCardBrand(
      this.formCardNumber
    );
    const enteredCardBrand = this.cardPaymentFormControls['cardBrand'].value;

    if (detectedCardBrand?.name === enteredCardBrand) {
      this.cardBrandValid = true;
      this.cardBrandErrorMessage = '';
      return;
    }
  }

  getCardBrands() {
    this.cardValidationService.getCardBrands().subscribe((cardBrands) => {
      this.cardBrand = cardBrands.map((card) => ({
        ...card,
        label: `${card.name}`,
        value: `${card.name}`,
      }));
    });
  }

  handleSelectedCardBrand(event: any) {
    this.cvvValid = true;
    this.cardBrandValid = true;

    this.cardPaymentForm.patchValue({
      cardBrand: event.value,
    });

    this.chosenCardCvvLength = event.cvvLength;

    this.validateCvv();
  }

  validateCvv() {
    this.cvvValid = true;
    this.cardBrandValid = true;

    const enteredCardBrand = this.cardPaymentFormControls['cardBrand'].value;
    const detectedCardBrand = this.cardValidationService.identifyCardBrand(
      this.formCardNumber
    );

    if (
      !detectedCardBrand ||
      this.chosenCardCvvLength !==
        this.cardPaymentFormControls['cvv'].value.length
    ) {
      this.cvvValid = false;
      this.cardBrandValid = false;
      this.cardBrandErrorMessage = 'Card brand does not match CVV';
      return;
    } else if (detectedCardBrand.name !== enteredCardBrand) {
      this.cardBrandValid = false;
      this.cardBrandErrorMessage = 'Card brand does not match card number';
      return;
    }

    this.cvvValid = this.cardValidationService.validateCvv(
      this.cardPaymentFormControls['cvv'].value,
      this.formCardNumber
    );
  }

  handleCardBrandPagination(): void {
    // Increase page size or page number and fetch more data
  }

  // Function to trigger validation errors
  revealValidationErrors(): void {
    Object.keys(this.cardPaymentForm.controls).forEach((controlName) => {
      this.cardPaymentForm.get(controlName)?.markAsTouched();
      this.cardPaymentForm.get(controlName)?.updateValueAndValidity();
    });
  }
}
