<form [formGroup]="usaAccountForm">
    <section id="usa-account">
        <div class="col-12">
            <seech-input formControlName="fullName" label="Account Name" id="account-name" placeholder="Enter full name"
                sch-validations />
        </div>

        <div class="col-12">
            <seech-input formControlName="accountNumber" label="Account Number" id="account-number"
                placeholder="Enter account number" sch-validations />
        </div>

        <div class="col-12">
            <seech-select label="Bank Name" formControlName="bankName" id="bank-name" [options]="bankNames"
                placeholder="Enter bank name" (reachedEndOfList)="handleBankNamePagination()"
                (selectedValueChange)="handleBankNameValueChanged($event)" sch-validations />
        </div>

        <div class="col-12">
            <seech-input formControlName="routingNumber" label="Routing Number" id="account-routingNumber"
                placeholder="Enter Routing Number" sch-validations />
        </div>

        <div class="col-12 mb-3">
            <seech-select formControlName="accountType" [id]="'card-type'" [options]="accountType"
                [placeholder]="'Choose account type'" [label]="'Account Type'"
                (reachedEndOfList)="handleCardTypePagination()" />
        </div>
    </section>
</form>