import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BankAccount } from '../../../models';
import { BankAccountClientService } from '../../../clients';
import { LookupClientService } from '../../../clients/lookup-client.service';
import { VALIDATION } from '../../../constants';

@Component({
  selector: 'seech-new-bank-payment',
  templateUrl: './new-bank-payment.component.html',
  styleUrl: './new-bank-payment.component.scss',
})
export class NewBankPaymentComponent implements OnInit, OnDestroy {
  bankAccounts: BankAccount[] = [];
  countries: any[] = [];

  newBankPaymentForm!: FormGroup;
  childFormValues: any = {}; // Separate object to store child form values

  @Output() formValuesChanged = new EventEmitter<any>();
  private subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private bankAccountClient: BankAccountClientService,
    private cdr: ChangeDetectorRef,
    private lookupService: LookupClientService
  ) {
    this.newBankPaymentForm = this.fb.group({
      country: ['', VALIDATION.countryValidator],
    });

    // Emit form values on changes
    this.newBankPaymentForm.valueChanges.subscribe(() => {
      this.emitCombinedFormValues();
    });
  }

  ngOnInit(): void {
    this.loadCountries();
  }

  loadCountries() {
    this.subscription.add(
      this.lookupService.getCountries().subscribe((count) => {
        this.countries = count.map((country) => ({
          ...country,
          label: `${country.name} - (${country.countryCode})`, // Customize as needed
          value: `${country.countryCode}`, // or any unique identifier
        }));
      })
    );
  }

  handleCountryPagination(): void {
    // Increase page size or page number and fetch more data
  }

  handleCountryValueChanged(event: any) {
    const newCountryValue = event.value;
    this.newBankPaymentForm.patchValue({ country: newCountryValue });
    this.getBankAccounts();
  }

  getBankAccounts() {
    this.subscription.add(
      this.bankAccountClient.getBankAccounts().subscribe({
        next: (resData) => {
          this.bankAccounts = resData.map((bank) => ({
            ...bank,
            label: `${bank.bankName}`, // Customize as needed
            value: `${bank.bankName}`, // or any unique identifier
          }));
          this.cdr.detectChanges();
        },
      })
    );
  }

  // Called when a child component emits form changes
  onChildFormValuesChanged(childFormValues: any) {
    // Update the child form values in a separate object
    this.childFormValues = { ...childFormValues };
    this.emitCombinedFormValues();
  }

  emitCombinedFormValues() {
    // Combine parent form values with child form values
    const fullFormValues = {
      ...this.newBankPaymentForm.value,
      accountDetails: this.childFormValues, // Include child values
    };

    // Emit the combined form values
    this.formValuesChanged.emit(fullFormValues);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
