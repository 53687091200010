import { Route } from '@angular/router';
// import { canActivateGuard } from '@seech-sub/core-ng';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './components/login.component';

export const appRoutes: Route[] = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'dashboard',
        // canActivate: [canActivateGuard],
        loadChildren: () => {
          return import('./modules/features/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          );
        },
      },
      {
        path: 'transactions',
        // canActivate: [canActivateGuard],
        loadChildren: () => {
          return import(
            './modules/features/transactions/transactions.module'
          ).then((m) => m.TransactionsModule);
        },
      },
      {
        path: 'subscriptions',
        // canActivate: [canActivateGuard],
        loadChildren: () => {
          return import(
            './modules/features/subscriptions/subscriptions.module'
          ).then((m) => m.SubscriptionsModule);
        },
      },
      {
        path: 'wallet',
        // canActivate: [canActivateGuard],
        loadChildren: () => {
          return import('./modules/features/wallet/wallet.module').then(
            (m) => m.WalletModule
          );
        },
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
];
