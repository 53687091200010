import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { PaymentNgModule } from '@seech/payment-ng';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './layout/layout.module';
import {
  InterceptorService,
  HttpProgressInterceptorInterceptor,
  CoreNgModule,
} from '@seech-sub/core-ng';
import { UxNgModule } from '@seech-sub/ux-ng';
import { ControlsNgModule } from '@seech/controls-ng';
import { environment } from '../environments/environment';

const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpProgressInterceptorInterceptor,
    multi: true,
  },
];
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    UxNgModule,
    HttpClientModule,
    LayoutModule,
    CoreNgModule.forRoot({ environment }),
    ControlsNgModule.forRoot({
      textboxVariant: 'classic',
    }),
    PaymentNgModule
  ],
  providers: [...httpInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
