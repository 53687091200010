import { Injectable } from '@angular/core';
import { bankAccount } from '../mock-data/mock';
import { of } from 'rxjs';
import { BankAccountService } from '../generated';

@Injectable({
  providedIn: 'root',
})
export class BankAccountClientService {
  constructor(private bankAccountService: BankAccountService) {}

  getBankAccounts() {
    return of(bankAccount);
    // return this.bankAccountService.bankAccountGetBankAccounts();
  }
}
