import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { VALIDATION } from '../../../../constants';

@Component({
  selector: 'seech-usa-account',
  templateUrl: './usa-account.component.html',
  styleUrl: './usa-account.component.scss',
})
export class UsaAccountComponent {
  @Input() bankNames: any[] = [];

  accountType = [
    {
      label: 'Savings',
      value: 'savings',
    },
    {
      label: 'Checking',
      value: 'checking',
    },
  ];

  @Output() formValuesChanged = new EventEmitter<any>(); // Output for two-way binding

  usaAccountForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.usaAccountForm = this.fb.group({
      fullName: ['', VALIDATION.accountNameValidator],
      accountNumber: ['', VALIDATION.accountNumberValidator],
      bankName: ['', VALIDATION.bankNameValidator],
      routingNumber: ['', VALIDATION.routingNumberValidator],
      accountType: 'checking',
    });

    // Emit form values on changes
    this.usaAccountForm.valueChanges.subscribe((values: any) => {
      this.formValuesChanged.emit(values);
    });
  }

  handleCardTypePagination(): void {
    // Increase page size or page number and fetch more data
  }

  handleBankNamePagination(): void {
    // Increase page size or page number and fetch more data
  }

  handleBankNameValueChanged(event: any) {
    this.usaAccountForm.patchValue({ bankName: event.value });
  }
}
