import { Component } from '@angular/core';
import { DiService } from '@seech-sub/core-ng';
import { NavItem, MenuItem } from '@seech-sub/layout-ng';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  showFooter = true;
  sidebarCollapsed = false;
  sidebarWidth = '20%';

  constructor(private coreDi: DiService) {}

  showMenu(): boolean {
    return !this.coreDi.environment?.production;
  }

  navItems: NavItem[][] = [
    [
      { label: 'Dashboard', link: 'dashboard', icon: 'dashboard-rounded' },
      { label: 'My Wallet', link: 'wallet', icon: 'wallet' },
      { label: 'Transactions', link: 'transactions', icon: 'receipt' },
      {
        label: 'Subscription',
        link: 'subscriptions',
        // isHidden: this.showMenu(),
        icon: 'paid',
      },
    ],
    [
      {
        label: 'Settings',
        link: 'settings',
        // isHidden: this.showMenu(),
        icon: 'settings',
      },
      {
        label: 'Help and Support',
        link: 'support',
        // isHidden: this.showMenu(),
        icon: 'support',
      },
    ],
  ];

  headerMenuItems: MenuItem[] = [
    { label: 'Accounts', routerLink: environment.ACCOUNT_WEB_URL, external: true },
    { label: 'Settings', routerLink: '/settings' },
    { label: 'Log out', action: () => this.onLogout() },
  ];

  onSidebarToggle(isCollapsed: boolean) {
    this.sidebarCollapsed = isCollapsed;
  }

  // Method to toggle the sidebar collapse state
  toggleSidebar(): void {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }

  onLogout() {
    // console.log('Logged out');
  }
}
