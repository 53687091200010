export const PaymentMethodMapping = {
  bankCard: {
    name: 'Bank Card (Debit / Credit / Prepaid)',
    value: 'new-card',
  },
  bankAccount: {
    name: 'Bank Account (ACH Debit)',
    value: 'new-bank',
  },
  paypal: {
    name: 'PayPal',
    value: 'new-paypal',
  },
  venmo: {
    name: 'Venmo',
    value: 'new-venmo',
  },
  default: {
    name: 'Default Payment Method',
    value: 'default',
  },
};
