import { AbstractControl, ValidationErrors } from '@angular/forms';

export const VALIDATION = {
  nameValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter your full name',
            severity: 'error',
          },
        },

  cardNumberValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter card number',
            severity: 'error',
          },
        },

  cardTypeValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please select a card type',
            severity: 'error',
          },
        },

  dateValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter a date',
            severity: 'error',
          },
        },

  cvvValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter CVV',
            severity: 'error',
          },
        },

  countryValidator: (control: AbstractControl): ValidationErrors | null => {
    const valid = control.value;
    return valid
      ? null
      : {
          requiredError: {
            message: 'Please select country',
            severity: 'error',
          },
        };
  },

  accountNameValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter your name',
            severity: 'error',
          },
        },

  accountNumberValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter your account number',
            severity: 'error',
          },
        },

  bankNameValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please select your bank',
            severity: 'error',
          },
        },

  swiftSortNumberValidator: (
    control: AbstractControl
  ): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter your swift/sort number',
            severity: 'error',
          },
        },

  routingNumberValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter your routing number',
            severity: 'error',
          },
        },
};
