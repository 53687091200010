import { PaymentMethodMapping } from '../constants';
import {
  BankCard,
  CardBrand,
  Currency,
  TransactionResponse,
  VirtualCard,
} from '../models';

export const transactions: TransactionResponse[] = [
  {
    id: 'tx1',
    referenceId: 'ref12345',
    description: 'Payment for invoice #123',
    redactedPaymentMethod: '**** **** **** 1234',
    createdOn: new Date('2023-09-15'),
    amount: 150.75,
    status: 'Completed',
  },
  {
    id: 'tx2',
    referenceId: 'ref67890',
    description: 'Online purchase',
    redactedPaymentMethod: '**** **** **** 5678',
    createdOn: new Date('2023-08-10'),
    amount: 89.99,
    status: 'Pending',
  },
  {
    id: 'tx3',
    referenceId: 'ref24680',
    description: 'Subscription renewal',
    redactedPaymentMethod: '**** **** **** 4321',
    createdOn: new Date('2023-07-05'),
    amount: 29.99,
    status: 'Failed',
  },
  {
    id: 'tx4',
    referenceId: 'ref13579',
    description: 'Refund for order #789',
    redactedPaymentMethod: '**** **** **** 8765',
    createdOn: new Date('2023-06-20'),
    amount: 45.0,
    status: 'Completed',
  },
  {
    id: 'tx5',
    referenceId: 'ref11223',
    description: 'Gift card purchase',
    redactedPaymentMethod: '**** **** **** 3456',
    createdOn: new Date('2023-05-15'),
    amount: 100.5,
    status: 'Cancelled',
  },
  {
    id: 'tx6',
    referenceId: 'ref55555',
    description: 'Hotel booking',
    redactedPaymentMethod: '**** **** **** 1111',
    createdOn: new Date('2023-10-01'),
    amount: 300.0,
    status: 'Completed',
  },
  {
    id: 'tx7',
    referenceId: 'ref33333',
    description: 'Concert ticket purchase',
    redactedPaymentMethod: '**** **** **** 2222',
    createdOn: new Date('2023-09-25'),
    amount: 120.0,
    status: 'Completed',
  },
  {
    id: 'tx8',
    referenceId: 'ref77777',
    description: 'Airline ticket refund',
    redactedPaymentMethod: '**** **** **** 3333',
    createdOn: new Date('2023-08-15'),
    amount: 200.0,
    status: 'Refunded',
  },
  {
    id: 'tx9',
    referenceId: 'ref88888',
    description: 'Monthly subscription fee',
    redactedPaymentMethod: '**** **** **** 4444',
    createdOn: new Date('2023-07-01'),
    amount: 19.99,
    status: 'Completed',
  },
  {
    id: 'tx10',
    referenceId: 'ref99999',
    description: 'Utility bill payment',
    redactedPaymentMethod: '**** **** **** 5555',
    createdOn: new Date('2023-06-15'),
    amount: 75.25,
    status: 'Pending',
  },
  {
    id: 'tx11',
    referenceId: 'ref67890',
    description: 'Online purchase',
    redactedPaymentMethod: '**** **** **** 5678',
    createdOn: new Date('2023-08-10'),
    amount: 89.99,
    status: 'Pending',
  },
  {
    id: 'tx12',
    referenceId: 'ref24680',
    description: 'Subscription renewal',
    redactedPaymentMethod: '**** **** **** 4321',
    createdOn: new Date('2023-07-05'),
    amount: 29.99,
    status: 'Failed',
  },
  {
    id: 'tx13',
    referenceId: 'ref13579',
    description: 'Refund for order #789',
    redactedPaymentMethod: '**** **** **** 8765',
    createdOn: new Date('2023-06-20'),
    amount: 45.0,
    status: 'Completed',
  },
  {
    id: 'tx14',
    referenceId: 'ref11223',
    description: 'Gift card purchase',
    redactedPaymentMethod: '**** **** **** 3456',
    createdOn: new Date('2023-05-15'),
    amount: 100.5,
    status: 'Cancelled',
  },
  {
    id: 'tx15',
    referenceId: 'ref55555',
    description: 'Hotel booking',
    redactedPaymentMethod: '**** **** **** 1111',
    createdOn: new Date('2023-10-01'),
    amount: 300.0,
    status: 'Completed',
  },
  {
    id: 'tx16',
    referenceId: 'ref33333',
    description: 'Concert ticket purchase',
    redactedPaymentMethod: '**** **** **** 2222',
    createdOn: new Date('2023-09-25'),
    amount: 120.0,
    status: 'Completed',
  },
  {
    id: 'tx17',
    referenceId: 'ref77777',
    description: 'Airline ticket refund',
    redactedPaymentMethod: '**** **** **** 3333',
    createdOn: new Date('2023-08-15'),
    amount: 200.0,
    status: 'Refunded',
  },
  {
    id: 'tx18',
    referenceId: 'ref88888',
    description: 'Monthly subscription fee',
    redactedPaymentMethod: '**** **** **** 4444',
    createdOn: new Date('2023-07-01'),
    amount: 19.99,
    status: 'Completed',
  },
  {
    id: 'tx19',
    referenceId: 'ref99999',
    description: 'Utility bill payment',
    redactedPaymentMethod: '**** **** **** 5555',
    createdOn: new Date('2023-06-15'),
    amount: 75.25,
    status: 'Pending',
  },
];

export const virtualCard: VirtualCard[] = [
  {
    id: 'vc1',
    cardHolderName: 'John Doe',
    cardNumber: '**** **** **** 1234',
    currencyCode: 'USD',
    cardType: 'Visa',
    statementBalance: 2000,
    runningBalance: 1500,
    spendLimit: 5000,
    createdOn: new Date('2023-06-15'),
    createdBy: 'admin',
    wallets: [],
  },
  {
    id: 'vc2',
    cardHolderName: 'Jane Smith',
    cardNumber: '**** **** **** 5678',
    currencyCode: 'EUR',
    cardType: 'MasterCard',
    statementBalance: 3000,
    runningBalance: 2750,
    spendLimit: 6000,
    createdOn: new Date('2023-03-20'),
    createdBy: 'admin',
    wallets: [],
  },
  {
    id: 'vc3',
    cardHolderName: 'Alice Johnson',
    cardNumber: '**** **** **** 4321',
    currencyCode: 'GBP',
    cardType: 'Visa',
    statementBalance: 1500,
    runningBalance: 1200,
    spendLimit: 4000,
    createdOn: new Date('2023-01-05'),
    createdBy: 'admin',
    wallets: [],
  },
  {
    id: 'vc4',
    cardHolderName: 'John Doe',
    cardNumber: '**** **** **** 1234',
    currencyCode: 'USD',
    cardType: 'Visa',
    statementBalance: 2000,
    runningBalance: 1500,
    spendLimit: 5000,
    createdOn: new Date('2023-06-15'),
    createdBy: 'admin',
    wallets: [],
  },
  {
    id: 'vc5',
    cardHolderName: 'Jane Smith',
    cardNumber: '**** **** **** 5678',
    currencyCode: 'EUR',
    cardType: 'MasterCard',
    statementBalance: 3000,
    runningBalance: 2750,
    spendLimit: 6000,
    createdOn: new Date('2023-03-20'),
    createdBy: 'admin',
    wallets: [],
  },
  {
    id: 'vc6',
    cardHolderName: 'Alice Johnson',
    cardNumber: '**** **** **** 4321',
    currencyCode: 'GBP',
    cardType: 'Visa',
    statementBalance: 1500,
    runningBalance: 1200,
    spendLimit: 4000,
    createdOn: new Date('2023-01-05'),
    createdBy: 'admin',
    wallets: [],
  },
];

export const bankAccount = [
  {
    id: 'ba1',
    bankName: 'Chase Bank',
    accountNumber: '****123456',
    branchCode: '021000021',
    currencyCode: 'USD',
    countryCode: 'US',
    accountType: 'Checking',
    accountDetails: 'Primary checking account',
    createdOn: new Date('2022-05-10'),
    createdBy: 'admin',
    wallets: [],
  },
  {
    id: 'ba2',
    bankName: 'Deutsche Bank',
    accountNumber: '****987654',
    branchCode: '10070000',
    currencyCode: 'EUR',
    countryCode: 'DE',
    accountType: 'Savings',
    accountDetails: 'Personal savings account',
    createdOn: new Date('2021-12-01'),
    createdBy: 'admin',
    wallets: [],
  },
  {
    id: 'ba3',
    bankName: 'HSBC',
    accountNumber: '****654321',
    branchCode: '400302',
    currencyCode: 'GBP',
    countryCode: 'UK',
    accountType: 'Current',
    accountDetails: 'Joint current account',
    createdOn: new Date('2023-02-10'),
    createdBy: 'admin',
    wallets: [],
  },
  {
    id: 'ba4',
    bankName: 'Bank of America',
    accountNumber: '****123456',
    branchCode: 'BOA001',
    currencyCode: 'USD',
    countryCode: 'US',
    accountType: 'Checking',
    accountDetails: 'Primary checking account for daily transactions.',
    description: 'Main personal account.',
    createdOn: new Date('2022-05-15'),
    createdBy: 'admin',
    wallets: [],
  },
  {
    id: 'ba5',
    bankName: 'HSBC',
    accountNumber: '****654321',
    branchCode: 'HSBC-123',
    currencyCode: 'GBP',
    countryCode: 'UK',
    accountType: 'Savings',
    accountDetails: 'High-interest savings account.',
    createdOn: new Date('2021-08-10'),
    createdBy: 'admin',
    isDeleted: false,
    wallets: [],
  },
  {
    id: 'ba6',
    bankName: 'Deutsche Bank',
    accountNumber: '****789012',
    branchCode: 'DE-70000',
    currencyCode: 'EUR',
    countryCode: 'DE',
    accountType: 'Business',
    accountDetails: 'Business account for corporate expenses.',
    description: 'Corporate funds account.',
    createdOn: new Date('2020-11-20'),
    createdBy: 'admin',
    isDeleted: true,
    deletedOn: new Date('2023-01-01'),
    wallets: [],
  },
  {
    id: 'ba7',
    bankName: 'Standard Chartered',
    accountNumber: '****345678',
    branchCode: 'SC-001',
    currencyCode: 'SGD',
    countryCode: 'SG',
    accountType: 'Investment',
    accountDetails: 'Account dedicated for investment transactions.',
    createdOn: new Date('2019-02-25'),
    createdBy: 'user1',
    isDeactivated: true,
    deactivatedOn: new Date('2023-03-15'),
    wallets: [],
  },
  {
    id: 'ba8',
    bankName: 'Royal Bank of Canada',
    accountNumber: '****234567',
    currencyCode: 'CAD',
    countryCode: 'CA',
    accountType: 'Checking',
    accountDetails: 'Joint account with spouse.',
    description: 'Family joint account for household expenses.',
    createdOn: new Date('2021-06-01'),
    createdBy: 'user2',
    wallets: [],
  },
  {
    id: 'ba9',
    bankName: 'Santander',
    accountNumber: '****987654',
    branchCode: 'SANT-876',
    currencyCode: 'EUR',
    countryCode: 'ES',
    accountType: 'Savings',
    accountDetails: 'High-interest account for future savings.',
    createdOn: new Date('2022-10-12'),
    createdBy: 'admin',
    isDeactivated: false,
    wallets: [],
  },
];

export const userSubscriptionDetails = [
  {
    image: `sample-sub-image.png`,
    name: 'Premium Subscription',
    description: 'Access to all premium features.',
    redactedPaymentMethod: '**** **** **** 1234',
    billingCycleInDays: 30,
    contractStartDate: new Date('2023-01-01'),
    contractEndDate: new Date('2024-01-01'),
    nextPaymentDate: new Date('2024-01-01'),
    price: 49.99,
    status: 'Active',
    statusMessage: 'Subscription is currently active.',
    isAutoRenewed: true,
    isAutoPaid: true,
  },
  {
    image: `sample-sub-image.png`,
    name: 'Basic Subscription',
    description: 'Limited access to basic features.',
    redactedPaymentMethod: '**** **** **** 5678',
    billingCycleInDays: 30,
    contractStartDate: new Date('2022-10-01'),
    contractEndDate: new Date('2023-10-01'),
    nextPaymentDate: new Date('2023-09-30'),
    price: 9.99,
    status: 'Active',
    statusMessage: 'Subscription is currently active.',
    pausedUntilDate: new Date('2023-12-01'),
    isAutoRenewed: false,
    isAutoPaid: true,
  },
  {
    image: `sample-sub-image.png`,
    name: 'Annual Plan',
    description: 'One-time payment for a full year of service.',
    redactedPaymentMethod: '**** **** **** 8765',
    billingCycleInDays: 365,
    contractStartDate: new Date('2023-05-01'),
    contractEndDate: new Date('2024-05-01'),
    nextPaymentDate: new Date('2024-05-01'),
    price: 120.0,
    status: 'Paused',
    statusMessage: 'Subscription is paused until December 2023.',
    pausedUntilDate: new Date('2023-12-31'),
    isAutoRenewed: true,
    isAutoPaid: false,
  },
  {
    image: `sample-sub-image.png`,
    name: 'Student Plan',
    description: 'Discounted plan for students.',
    redactedPaymentMethod: '**** **** **** 4321',
    billingCycleInDays: 180,
    contractStartDate: new Date('2022-08-15'),
    contractEndDate: new Date('2023-08-15'),
    nextPaymentDate: new Date('2023-08-15'),
    price: 19.99,
    status: 'Cancelled',
    statusMessage: 'Subscription has been cancelled.',
    cancelledOn: new Date('2023-07-01'),
    isAutoRenewed: false,
    isAutoPaid: false,
  },
  {
    image: `sample-sub-image.png`,
    name: 'Family Plan',
    description: 'Subscription for up to 5 family members.',
    redactedPaymentMethod: '**** **** **** 8765',
    billingCycleInDays: 30,
    contractStartDate: new Date('2023-02-01'),
    contractEndDate: new Date('2023-12-31'),
    nextPaymentDate: new Date('2023-12-01'),
    price: 39.99,
    status: 'Active',
    statusMessage: 'Subscription is active and auto-renews monthly.',
    isAutoRenewed: true,
    isAutoPaid: true,
  },
  {
    image: `sample-sub-image.png`,
    name: 'Corporate Plan',
    description: 'Enterprise subscription for corporate users.',
    redactedPaymentMethod: '**** **** **** 1122',
    billingCycleInDays: 90,
    contractStartDate: new Date('2023-03-01'),
    contractEndDate: new Date('2024-03-01'),
    nextPaymentDate: new Date('2023-12-01'),
    price: 299.99,
    status: 'Paid Off',
    statusMessage: 'Subscription fully paid for the contract period.',
    paidOffOn: new Date('2023-03-01'),
    isAutoRenewed: false,
    isAutoPaid: true,
  },
  {
    image: `sample-sub-image.png`,
    name: 'Mega Plan',
    description: 'Enterprise subscription for mega users.',
    redactedPaymentMethod: '**** **** **** 1122',
    billingCycleInDays: 120,
    contractStartDate: new Date('2023-03-01'),
    contractEndDate: new Date('2024-03-01'),
    nextPaymentDate: new Date('2023-12-01'),
    price: 499.99,
    status: 'Expired',
    statusMessage: 'Subscription has expired.',
    paidOffOn: new Date('2023-03-01'),
    isAutoRenewed: true,
    isAutoPaid: false,
  },
];

export const countries = [
  {
    id: '1',
    name: 'United States',
    shortName: 'US',
    countryCode: 'USA',
    phoneCode: '+1',
    createdOn: new Date('1776-07-04'),
    countryCurrencies: [],
  },
  {
    id: '2',
    name: 'United Kingdom',
    shortName: 'UK',
    countryCode: 'GBR',
    phoneCode: '+44',
    createdOn: new Date('1707-05-01'),
    countryCurrencies: [],
  },
  {
    id: '3',
    name: 'Canada',
    shortName: 'CA',
    countryCode: 'CAN',
    phoneCode: '+1',
    createdOn: new Date('1867-07-01'),
    countryCurrencies: [],
  },
  {
    id: '4',
    name: 'Japan',
    shortName: 'JP',
    countryCode: 'JPN',
    phoneCode: '+81',
    createdOn: new Date('1947-05-03'),
    countryCurrencies: [],
  },
  {
    id: '5',
    name: 'Australia',
    shortName: 'AU',
    countryCode: 'AUS',
    phoneCode: '+61',
    createdOn: new Date('1901-01-01'),
    countryCurrencies: [],
  },
  {
    id: '6',
    name: 'Germany',
    shortName: 'DE',
    countryCode: 'DEU',
    phoneCode: '+49',
    createdOn: new Date('1871-01-18'),
    countryCurrencies: [],
  },
];

export const mockCurrencies: Currency[] = [
  {
    id: '1',
    currencyCode: 'USD',
    currencyName: 'US Dollar',
    currencySymbol: '$',
    countryCode: 'USA',
    countryCurrencies: [],
  },
  {
    id: '2',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    countryCode: 'EUR',
    countryCurrencies: [],
  },
  {
    id: '3',
    currencyCode: 'JPY',
    currencyName: 'Japanese Yen',
    currencySymbol: '¥',
    countryCode: 'JPN',
    countryCurrencies: [],
  },
  {
    id: '4',
    currencyCode: 'GBP',
    currencyName: 'Pound Sterling',
    currencySymbol: '£',
    countryCode: 'GBR',
    countryCurrencies: [],
  },
  {
    id: '5',
    currencyCode: 'AUD',
    currencyName: 'Australian Dollar',
    currencySymbol: '$',
    countryCode: 'AUS',
    countryCurrencies: [],
  },
  {
    id: '6',
    currencyCode: 'CAD',
    currencyName: 'Canadian Dollar',
    currencySymbol: '$',
    countryCode: 'CAN',
    countryCurrencies: [],
  },
];

export const bankCards: BankCard[] = [
  {
    id: 'bc1',
    cardHolderName: 'John Doe',
    cardNumber: '**** **** **** 1234',
    cvv: '123',
    issuer: 'Chase Bank',
    cardType: 'Credit',
    currencyCode: 'USD',
    expirationDate: '12/25',
    cardBrand: 'Visa',
    description: 'Primary credit card',
    createdBy: 'admin',
    createdOn: new Date('2023-01-15'),
    wallets: [],
  },
  {
    id: 'bc2',
    cardHolderName: 'Jane Smith',
    cardNumber: '**** **** **** 5678',
    cvv: '456',
    issuer: 'Bank of America',
    cardType: 'Debit',
    currencyCode: 'USD',
    expirationDate: '11/24',
    cardBrand: 'MasterCard',
    description: 'Business debit card',
    createdBy: 'admin',
    createdOn: new Date('2022-07-20'),
    deletedOn: new Date('2023-06-01'),
    isDeleted: true,
    wallets: [],
  },
  {
    id: 'bc3',
    cardHolderName: 'Alice Johnson',
    cardNumber: '**** **** **** 9876',
    cardType: 'Credit',
    currencyCode: 'EUR',
    expirationDate: '08/26',
    cardBrand: 'American Express',
    description: 'Travel card',
    createdBy: 'admin',
    createdOn: new Date('2021-03-10'),
    deactivatedOn: new Date('2023-01-10'),
    isDeactivated: true,
    wallets: [],
  },
  {
    id: 'bc4',
    cardHolderName: 'Michael Brown',
    cardNumber: '**** **** **** 6543',
    cvv: '789',
    issuer: 'HSBC',
    cardType: 'Debit',
    currencyCode: 'GBP',
    expirationDate: '05/23',
    cardBrand: 'Visa',
    description: 'Personal account debit card',
    createdBy: 'admin',
    createdOn: new Date('2020-09-25'),
    wallets: [],
  },
  {
    id: 'bc5',
    cardHolderName: 'Sarah Wilson',
    cardNumber: '**** **** **** 3210',
    cvv: '101',
    issuer: 'Citibank',
    cardType: 'Credit',
    currencyCode: 'AUD',
    expirationDate: '09/24',
    cardBrand: 'MasterCard',
    description: 'Emergency card',
    createdBy: 'admin',
    createdOn: new Date('2023-02-01'),
    wallets: [],
  },
  {
    id: 'bc6',
    cardHolderName: 'David Lee',
    cardNumber: '**** **** **** 4567',
    cardType: 'Debit',
    currencyCode: 'CAD',
    expirationDate: '07/25',
    cardBrand: 'Visa',
    createdBy: 'admin',
    createdOn: new Date('2019-11-11'),
    isDeleted: false,
    wallets: [],
  },
];

export const quotes = {
  name: 'Online Subscription',
  description: 'Authorization for recurring subscription payment.',
  additionalInfo: {
    invoiceId: 'INV001',
    service: 'Streaming',
    plan: 'Premium',
  },
  acceptAnyAmount: true,
  allowScheduling: true,
  supportedPaymentMethods: [
    {
      id: '1',
      name: PaymentMethodMapping.bankCard.name,
      description:
        'credit cards for online and in-store payments or debit card linked to your bank account',
      imageUrl: `card-wallet.svg`,
    },
    {
      id: '2',
      name: PaymentMethodMapping.bankAccount.name,
      description: 'Direct bank debit for secure payments from your account',
      imageUrl: `bank-image.svg`,
    },
    {
      id: '3',
      name: PaymentMethodMapping.paypal.name,
      description: 'Secure online payment method using your PayPal balance',
      imageUrl: `paypal.svg`,
    },
    {
      id: '4',
      name: PaymentMethodMapping.venmo.name,
      description: 'Secure online payment method using your Venmo balance',
      imageUrl: `venmo.svg`,
    },
  ],
  savedPaymentMethods: [
    { id: 'spm1', type: 'creditCard', name: 'My Visa Card' },
    { id: 'spm2', type: 'wallet', name: 'My Digital Wallet' },
  ],
  payments: [
    {
      amount: 49.99,
      description: 'Monthly Subscription Fee',
      dueDate: new Date('2024-12-01'),
    },
    {
      amount: 149.99,
      description: 'Quarterly Subscription Fee',
      dueDate: new Date('2024-03-01'),
    },
  ],
};
// {
//   name: 'Quote for Project Alpha',
//   description: 'Initial phase development for Project Alpha',
//   invoiceId: 'INV001',
//   acceptAnyAmount: false,
//   payments: [
//     { amount: 1500, dueDate: new Date('2024-12-01') },
//     { amount: 2000, dueDate: new Date('2025-01-15') },
//   ],
// };

export const paymentMethods = [
  {
    id: '1',
    name: PaymentMethodMapping.bankCard.name,
    description:
      'credit cards for online and in-store payments or debit card linked to your bank account',
    imageUrl: `card-wallet.svg`,
  },
  {
    id: '2',
    name: PaymentMethodMapping.bankAccount.name,
    description: 'Direct bank debit for secure payments from your account',
    imageUrl: `bank-image.svg`,
  },
  {
    id: '3',
    name: PaymentMethodMapping.paypal.name,
    description: 'Secure online payment method using your PayPal balance',
    imageUrl: `paypal.svg`,
  },
  {
    id: '4',
    name: PaymentMethodMapping.venmo.name,
    description: 'Secure online payment method using your Venmo balance',
    imageUrl: `venmo.svg`,
  },
];

export const cardBrands: CardBrand[] = [
  {
    name: 'Visa',
    cardNumberRegex: '^4[0-9]{12}(?:[0-9]{3})?$',
    cvvRegex: '^[0-9]{3}$',
    cvvLength: 3,
    cardNumberLengths: [13, 16],
  },
  {
    name: 'MasterCard',
    cardNumberRegex: '^5[1-5][0-9]{14}$|^2[2-7][0-9]{14}$',
    cvvRegex: '^[0-9]{3}$',
    cvvLength: 3,
    cardNumberLengths: [16],
  },
  {
    name: 'American Express',
    cardNumberRegex: '^3[47][0-9]{13}$',
    cvvRegex: '^[0-9]{4}$',
    cvvLength: 4,
    cardNumberLengths: [15],
  },
  {
    name: 'Discover',
    cardNumberRegex: '^6(?:011|5[0-9]{2})[0-9]{12}$',
    cvvRegex: '^[0-9]{3}$',
    cvvLength: 3,
    cardNumberLengths: [16],
  },
  {
    name: 'JCB',
    // cardNumberRegex: '/^(?:2131|1800|35d{3})d{11}$/',
    // cvvRegex: '/^d{3}$/',
    cardNumberRegex: '^(?:2131|1800|35\\d{3})\\d{11}$',
    cvvRegex: '^[0-9]{3}$',
    cvvLength: 3,
    cardNumberLengths: [16],
  },
  {
    name: 'Diners Club',
    cardNumberRegex: '^3(?:0[0-5]|[68][0-9])[0-9]{11}$',
    cvvRegex: '^[0-9]{3}$',
    cvvLength: 3,
    cardNumberLengths: [14],
  },
  {
    name: 'UnionPay',
    cardNumberRegex: '^62[0-9]{14,17}$',
    cvvRegex: '/^d{3}$/',
    cvvLength: 3,
    cardNumberLengths: [16, 17, 18, 19],
  },
  {
    name: 'RuPay',
    cardNumberRegex: '^6[0-9]{15}$',
    cvvRegex: '/^d{3}$/',
    cvvLength: 3,
    cardNumberLengths: [16],
  },
  {
    name: 'Troy',
    cardNumberRegex: '^9[0-9]{15}$',
    cvvRegex: '/^d{3}$/',
    cvvLength: 3,
    cardNumberLengths: [16],
  },
];
