<div id="payment-auth">
    <header class="bg-primary px-4 rounded-t-lg header">
        <div class="flex justify-between items-center h-full">
            <!-- title and close icon -->
            <div>
                <h1 class="text-xl font-medium text-white-100">
                    {{ title }}
                </h1>
            </div>
            <div>
                <p (click)="closeModal()" role="button">
                    <i [sch-icon]="'cancel'" class="header-icon font-light text-white-100"></i>
                </p>
            </div>
        </div>
    </header>

    <div class="px-4 py-3">
        <section>
            <div class="data-section">
                <p class="info-text">Payment Information</p>

                <div class="info-wrapper">
                    <div class="info-row">
                        <p class="info-row-label">Name:</p>
                        <p class="info-row-value">{{paymentQuote?.name}}</p>
                    </div>

                    <div class="info-row">
                        <p class="info-row-label">Description:</p>
                        <p class="info-row-value">{{paymentQuote?.description}}</p>
                    </div>

                    <div class="info-row">
                        <p class="info-row-label">Invoice ID:</p>
                        <p class="info-row-value">{{paymentQuote?.additionalInfo?.invoiceId}}</p>
                    </div>

                    <div class="info-row">
                        <p class="info-row-label">Due Date:</p>
                        <p class="info-row-value">{{paymentQuote?.payments[0].dueDate| schDate: 'US'}}</p>
                    </div>
                </div>
            </div>

            <div class="payment-section">
                <div class="header-section">
                    <p *ngIf="modeOfPayment === authPaymentMode.default.value" class="info-text">Payment Information</p>

                    <div class="d-flex align-items-center gap-1" *ngIf="modeOfPayment !== authPaymentMode.default.value"
                        role="button" (click)="modeOfPayment = authPaymentMode.default.value">
                        <i [sch-icon]="'prev-arrow'" class="text-primary"></i>
                        <p class="saved-text text-primary">Saved <span class="d-none d-sm-inline">Payment</span> Methods
                        </p>
                    </div>

                    <div class="d-flex align-items-center">
                        <seech-dropdown [id]="'dropdown'" alignment="end" [showDropDownIcon]="false" [hideBorder]="true"
                            (click)="$event.stopPropagation()">
                            <div class="d-flex align-items-center" role="button" toggle>
                                <i [sch-icon]="'add-circle'" class="header-icon text-primary"></i>
                                <p class="header-text text-primary">New <span class="d-none d-sm-inline">Payment</span>
                                    Method
                                </p>
                                <i [sch-icon]="'collapse'" class="collapse-icon"></i>
                            </div>

                            <ng-container *ngFor="let option of paymentMethods; let optionIndex = index">
                                <li>
                                    <a sch-dropdown-item (click)="onDropdownClicked(option.value)"
                                        id="item-{{ optionIndex }}" role="button" class="dropdown-label">
                                        <img [src]="option.imageUrl" class="dropdown-image" />
                                        {{option.label}}
                                    </a>
                                </li>
                            </ng-container>
                        </seech-dropdown>
                    </div>
                </div>

                <ng-container *ngIf="modeOfPayment === authPaymentMode.default.value">
                    <seech-nested-select [id]="'source-select'" [options]="existingPaymentMethods"
                        [placeholder]="'Choose option'" (selected)="handleSelectedPaymentMethod($event)"
                        (reachedEndOfList)="handlePaymentMethodPagination()" placeholder="Choose option" />
                </ng-container>

                <div class="mode-wrapper" *ngIf="modeOfPayment !== authPaymentMode.default.value">
                    <ng-container *ngIf="modeOfPayment === authPaymentMode.bankCard.value">
                        <seech-new-card-payment (formValuesChanged)="handleNewPaymentFormUpdate($event)" />
                    </ng-container>

                    <ng-container *ngIf="modeOfPayment === authPaymentMode.bankAccount.value">
                        <seech-new-bank-payment (formValuesChanged)="handleNewPaymentFormUpdate($event)" />
                    </ng-container>

                    <div class="d-flex flex-col gap-2">
                        <div sch-checkbox [label]="'Securely save this for a faster checkout next time'"
                            id="securely-save" shape="normal">
                            <input type="checkbox" [(ngModel)]="saveForCheckout" [value]="true">
                        </div>

                        <div sch-checkbox [label]="'Set as default payment method.'" id="as-default" shape="normal">
                            <input type="checkbox" [(ngModel)]="defaultPayment" [value]="true">
                        </div>
                    </div>
                </div>


            </div>

            <div class="options-section">
                <p class="info-text">Payment Options</p>

                <div class="options-wrapper">
                    <ng-container *ngFor="let payment of paymentQuote?.payments; let i = index">
                        <div sch-radio
                            [label]="getFormattedAmount(i === 0 ? 'Payment Due Now' : 'Pay Off Amount', payment.amount)"
                            [id]="'payment' + i" shape="circle">
                            <input type="radio" [(ngModel)]="selectedAmountToPay" [value]="payment.amount"
                                (change)="otherAmountSelected = false">
                        </div>
                    </ng-container>

                    <div sch-radio *ngIf="paymentQuote?.acceptAnyAmount" [label]="'Other Amount'" id="otherAmount"
                        shape="circle">
                        <input type="radio" [(ngModel)]="otherAmountSelected" [value]="true"
                            (change)="selectedAmountToPay = ''">
                    </div>

                    <div class="col-12" *ngIf="otherAmountSelected">
                        <seech-input label="" id="amountEntered" placeholder="Enter amount" sch-validations
                            [(ngModel)]="customAmount" (ngModelChange)="selectedAmountToPay = customAmount" />
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column gap-2 mb-4" *ngIf="paymentQuote?.allowScheduling">
                <div class="col-12">
                    <p class="info-text mb-2">Payment Date</p>
                    <seech-date [(value)]="selectedDate" [label]="''" [id]="'scheduled-date'"
                        [placeholder]="'Select a Date'" [minDate]="minDate" />
                </div>

                <p class="date-desc">Payment dates & times are based on Eastern Time (ET)</p>
            </div>

            <button sch-button [size]="'md'" variant="solid" color="primary" class="w-100" (click)="payAmount()">
                Pay {{selectedAmountToPay ? '$' + selectedAmountToPay : ''}}
            </button>
        </section>
    </div>
</div>