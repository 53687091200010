import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlsNgModule, CheckboxDirective } from '@seech/controls-ng';
import { NewBankPaymentComponent } from './new-bank-payment.component';
import { InternationalAccountComponent } from './international-account/international-account.component';
import { UsaAccountComponent } from './usa-account/usa-account.component';

@NgModule({
  declarations: [
    NewBankPaymentComponent,
    InternationalAccountComponent,
    UsaAccountComponent,
  ],
  imports: [CommonModule, FormsModule, ControlsNgModule, CheckboxDirective, ReactiveFormsModule],
  exports: [NewBankPaymentComponent],
})
export class NewBankPaymentModule {}
