import { dev } from "@seech-sub/core-ng";
import { Environment } from "../app/models/environment";

export const environment: Environment = {
  ...dev,

  BASE_API_URL: 'https://dev-api-payment-seech.azurewebsites.net/api/',
  CDN_BASE_URL: 'https://dev-cdn.seech.com/payment',
  CLIENT_ID: 'DDF7792B-8EFD-4421-A4A6-AA35FBE45694', // TODO: Update analytics ID
  GOOGLE_ANALYTICS_ID: '',
  GOOGLE_API_KEY: 'AIzaSyBXozDBQk-HzM3s9bzeTc5XfvVGaYLNvnA',
};
