import { Injectable } from '@angular/core';
import {
  bankAccount,
  bankCards,
  cardBrands,
  countries,
  mockCurrencies,
  paymentMethods,
  quotes,
  virtualCard,
} from '../mock-data/mock';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LookupClientService {
  getPaymentMethods() {
    return of(paymentMethods);
  }

  getQuotes() {
    return of(quotes);
  }

  getVirtualCards() {
    return of(virtualCard);
  }

  getCardBrands() {
    return of(cardBrands);
  }

  getCountries() {
    return of(countries);
  }

  getCurrencies() {
    return of(mockCurrencies);
  }

  getBankCards() {
    return of(bankCards);
  }

  getBankAccounts() {
    return of(bankAccount);
  }
}
