<form [formGroup]="cardPaymentForm">
    <section id="new-card-payment">
        <div class="col-12">
            <seech-input formControlName="fullName" label="Enter Full Name On Card" id="card-name"
                placeholder="Name on your card" sch-validations />
        </div>

        <div class="col-12">
            <seech-input formControlName="cardNumber" label="Enter Card Number" id="card-number"
                placeholder="Enter your card number" (input)="onCardNumberChange()" (change)="validateCardNumber()"
                sch-validations />

            <seech-validation *ngIf="!cardNumberValid" type="error" [message]="getErrorMessage('cardNumber')" />
        </div>

        <div class="col-12">
            <seech-select formControlName="cardType" [id]="'card-type'" [options]="cardType"
                [placeholder]="'Choose card type'" [label]="'Card Type'" (selected)="handleSelectedCardType($event)"
                (reachedEndOfList)="handleCardTypePagination()" sch-validations />

            <seech-validation *ngIf="!cardTypeValid" type="error" [message]="cardTypeErrorMessage" />
        </div>

        <div class="col-12 bottom-items">
            <div>
                <seech-date formControlName="expirationDate" [(value)]="selectedDate" [label]="'Expiration Date'"
                    [id]="'card-expDate'" [placeholder]="'Select a Date'" sch-validations [minDate]="minDate" />
            </div>

            <div>
                <seech-password formControlName="cvv" label="CVV" id="card-cvv" placeholder="Enter CVV"
                    [maxLength]="chosenCardCvvLength" (change)="validateCvv()" sch-validations />

                <seech-validation *ngIf="!cvvValid" type="error" [message]="getErrorMessage('cvv')" />
            </div>
        </div>
    </section>
</form>