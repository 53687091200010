import { dev } from "@seech-sub/core-ng";
import { Environment } from "../app/models/environment";

export const environment: Environment = {
  ...dev,

  BASE_API_URL: 'https://dev-api-payment-seech.azurewebsites.net', // /api/
  CDN_BASE_URL: 'https://dev-cdn.seech.com/payment',
  CLIENT_ID: 'E1F2D0CA-3079-44D1-8F87-465C39360A2D',
  GOOGLE_ANALYTICS_ID: '',
  GOOGLE_API_KEY: 'AIzaSyCR9vRtCUFO7Wbne2xG8-UMNxcLlxglNjE',
};
